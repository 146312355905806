@use '@humans-sdk/enterprise/theme/styles/breakpoints.module.scss';
@use '~include-media/dist/include-media';

.root {
    display: grid;
    width: 360px;
    align-items: center;
    justify-content: center;
    padding: var(--qs-5x) 0;
    margin: 0 auto;

    .icon {
        width: calc(var(--qs) * 41);
        height: calc(var(--qs) * 46);
        margin: 0 auto calc(var(--qs) * 18);
    }

    @include include-media.media('<phone') {
        width: 300px;
    }
}

.caption {
    margin-bottom: var(--qs-6x);
    text-align: center;
}

.text {
    margin-bottom: calc(var(--qs) * 13);
    text-align: center;
}
