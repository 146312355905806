.root {
    max-height: 40px;
    background: var(--color-basic-quaternary-s-u);
    cursor: pointer;

    @media (hover: hover) {
        &:hover {
            box-shadow: var(--shadow-1);

            .name {
                scale: 1.05;
            }
        }
    }

    .content {
        padding: var(--qs-2x);
        user-select: none;
    }

    .compactContent {
        display: flex;
        justify-content: center;
        padding: var(--qs-2x);
    }

    .name {
        display: flex;
        width: 100%;
        align-items: center;
        text-overflow: ellipsis;
    }

    .shortName {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-overflow: ellipsis;
    }

    .fullName {
        overflow: hidden;
        justify-content: flex-start;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .icon {
        font-size: var(--h3-font-size);
    }

    .expandIcon {
        margin-right: var(--qs-2x);
    }
}
