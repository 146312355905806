@use '@humans-sdk/enterprise/theme/styles/breakpoints.module.scss';
@use '~include-media/dist/include-media';

:root {
    --resize-button-z-index: 1;
}

.root {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 120px);
}

.widgets {
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    grid-gap: var(--qs-4x);
    grid-template-rows: minmax(100px, 50%) minmax(100px, 50%);

    &:empty {
        background-color: var(--color-basic-primary-i-u-s);
        border-radius: var(--border-radius);
    }

    @include include-media.media('<tablet') {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: unset;
    }
}

@include include-media.media('<tablet') {
    .root {
        height: 100%;
        padding: 0 var(--qs-4x) var(--qs-4x);
        overflow-y: auto;
    }
}

.dropPlace {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.resizeButton {
    position: absolute;
    z-index: var(--resize-button-z-index);
    top: 0%;
    width: 16px;
    height: 100%;
    border: 0;
    background-color: transparent;
    cursor: col-resize;
}

.resizeButtonCenterLine {
    position: absolute;
    z-index: var(--resize-button-z-index);
    top: 0%;
    left: 50%;
    width: 2px;
    height: 100%;
    border: 0;
    background-color: var(--color-shadows-container);
    transform: translate(-50%, 0%);
}

.resizeButton:hover {
    .resizeButtonCenterLine {
        background-color: var(--color-basic-tertiary-s-u);
    }
}

.slots {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    height: 100%;
    max-height: inherit;
    align-items: flex-start;
    grid-gap: var(--qs-4x);
    grid-template-rows: 1fr 1fr;

    @include include-media.media('<tablet') {
        right: 0;
        bottom: var(--qs-4x);
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;
    }
}
