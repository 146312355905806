@use '@humans-sdk/enterprise/theme/styles/breakpoints.module.scss';
@use '~include-media/dist/include-media';

$z-index9999: 9999;

.root {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    background-color: var(--color-basic-primary-i-u-s-d);

    @include include-media.media('>=desktop') {
        padding: var(--qs-6x);
        gap: var(--qs-6x);
    }
}

.main {
    display: flex;
    width: 100%;
    height: 100%;
    gap: var(--qs-4x);

    @include include-media.media('<desktop') {
        overflow: auto;
    }
}

.menu {
    width: 100%;
    max-width: 200px;
    height: 100%;
    max-height: calc(100vh - 120px);
    padding: var(--qs-4x);
    border: 1px solid var(--color-basic-quaternary-s);
    backdrop-filter: blur(10px);
    background-color: var(--color-basic-primary-s-i);
    border-radius: var(--border-radius);
}

.menuCompact {
    width: 100%;
    max-width: 82px;
}

.menuHeader {
    position: relative;
    display: flex;
    height: 24px;
    justify-content: center;
}

.title {
    line-height: var(--h4-line-height);
    text-align: center;
}

.library {
    margin-top: var(--qs-6x);
}

.expand {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid var(--color-basic-tertiary-s);
    transform: rotate(90deg);
}

.expandRotate {
    position: relative;
    margin: 0 auto;
    transform: rotate(-90deg);
}

.button {
    position: fixed;
    z-index: $z-index9999;
    right: var(--qs-4x);
    bottom: var(--qs-4x);
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 50%;
}

.selectWidgetsImg {
    width: 24px;
    height: 24px;
}
