:root {
    --drop-place-available-z-index: 3;
    --drop-place-available-hover-z-index: 10;
    --drop-place-available-menu-z-index: 1301;
    --drop-place-available-content: '\FF0B';
    --drop-place-available-font-size: 2.625rem;
}

%drop {
    position: relative;
    border: 1px solid var(--color-basic-quaternary-s);
    backdrop-filter: blur(10px);
    background-color: var(--color-shadows-container);

    &::after {
        position: absolute;
        z-index: var(--drop-place-available-z-index);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: grid;
        background-color: var(basic-tertiary-s-u);
        border-radius: var(--border-radius);
        color: var(--color-basic-primary-i-s);
        content: var(--drop-place-available-content);
        font-size: var(--drop-place-available-font-size);
        opacity: 0.8;
        place-content: center;
        white-space: nowrap;
    }
}

.root {
    border-radius: var(--border-radius);

    &.available {
        @extend %drop;
    }

    &.active {
        @extend %drop;

        z-index: var(--drop-place-available-hover-z-index);
    }

    &.hovered {
        &::after {
            background-color: var(--color-functional-positive-s-u);
            opacity: 0.8;
        }
    }
}
