.root {
    height: calc(100% - var(--qs-8x));
    padding-bottom: var(--qs-6x);
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0;
    }
}

.list {
    display: grid;
    grid-row-gap: var(--qs-2x);

    &.on {
        padding-bottom: var(--qs-10x);

        &:empty {
            padding-bottom: 0;
        }
    }
}

.clear {
    display: flex;
    height: 40px;
    justify-content: flex-start;
    padding: var(--qs-2x);
    cursor: pointer;
}

.clearIconCollapse {
    justify-content: center;
    padding: 0;
}

.clearIcon {
    font-size: var(--h2-font-size);
}

.clearTitle {
    margin-left: var(--qs);
}
