@use '@humans-sdk/enterprise/theme/styles/breakpoints.module.scss';
@use '~include-media/dist/include-media';

$z-index3: 3;

:root {
    --header-menu-item-height: 40px;
}

.root {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--qs-4x);

    @include include-media.media('<desktop') {
        padding: var(--qs-3x) var(--qs-4x);
    }
}

.wrapper {
    display: grid;
    width: 100%;
    align-items: center;
    gap: var(--qs-4x);
    grid-template-columns: minmax(180px, 3fr) 2fr;

    @include include-media.media('>=desktop') {
        display: flex;
        justify-content: space-between;
    }

    @include include-media.media('<phone') {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.link {
    color: var(--color-basic-primary-s);
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-bold);
    text-decoration: none;
    white-space: nowrap;

    button {
        padding: var(--qs-2x) var(--qs-4x);

        @include include-media.media('<tablet') {
            padding: var(--qs-2x) 0;
        }
    }

    &.active {
        order: -1;
        color: var(--color-basic-primary-s);

        button {
            background-color: var(--color-basic-primary-s-i);
            touch-action: none;

            @include include-media.media('<tablet') {
                background-color: transparent;
            }
        }
    }
}

.linkHeader {
    display: flex;
    overflow: hidden;
    align-items: center;
    padding-bottom: var(--qs);
    gap: var(--qs-2x);
    text-overflow: ellipsis;
}

.avatarBlock {
    display: flex;
    width: 100%;
}

.avatar {
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    text-decoration: none;
}

.info {
    display: flex;
    min-width: calc(var(--qs) * 37);
    flex-direction: column;
    padding-right: var(--qs-3x);
    gap: var(--qs);
}

.buttonList {
    display: none;
}

.listDesktop {
    display: grid;
    align-items: center;
    justify-content: flex-start;
    grid-auto-flow: column;
    grid-gap: var(--qs-4x);
    transition: height ease 300ms;

    @include include-media.media('<tablet') {
        grid-gap: unset;
    }
}

@include include-media.media('<desktop') {
    .root {
        position: relative;
        z-index: $z-index3;
        padding: var(--qs-6x) var(--qs-4x) var(--qs-2x);
        grid-gap: var(--qs-2x);
        grid-template-columns: min-content 1fr;

        .list {
            display: none;
        }

        &.open {
            .list {
                position: absolute;
                top: calc(var(--qs-2x) * 8);
                right: var(--qs-4x);
                display: flex;
                width: 290px;
                height: max-content;
                flex-direction: column;
                align-items: flex-start;
                padding: var(--qs-4x);
                background: var(--color-basic-primary-s-i);
                border-radius: calc(var(--border-radius));
                box-shadow: var(--shadow-container);

                @include include-media.media('<phone') {
                    top: calc(var(--qs-2x) * 14);
                    right: unset;
                    left: var(--qs-4x);
                    width: 240px;
                }
            }
        }
    }

    .blockSelect {
        display: flex;
        width: 100%;
        min-width: var(--qs-8x);
        justify-content: end;

        @include include-media.media('<phone') {
            justify-content: start;
        }
    }

    .buttonList {
        display: inline-flex;
        min-width: calc(var(--qs) * 39);
        max-width: fit-content;
        padding-right: var(--qs-2x);
        background-color: var(--color-basic-primary-s-i);
        font-weight: var(--font-weight-normal);
        gap: var(--qs);
    }
}

.expandRotate {
    transform: rotate(180deg);
}

.formRadio {
    padding: 0;
}

.radioGroup {
    row-gap: var(--qs-6x);
}

.radioRoot {
    h5 {
        overflow: hidden;
        width: 230px;
        font-size: var(--interface-font-size-m);
        line-height: var(--interface-line-height-m);
        text-overflow: ellipsis;
        white-space: nowrap;

        @include include-media.media('<=tablet') {
            font-size: var(--interface-font-size-s);
            line-height: var(--interface-line-height-s);
        }

        @include include-media.media('<phone') {
            width: 185px;
            font-size: var(--interface-font-size-xs);
            line-height: var(--interface-line-height-xs);
        }
    }
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.img {
    border: none;
}
