.root {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--color-basic-tertiary-s-u);
    border-radius: var(--border-radius);

    .workspaceIcon {
        width: calc(var(--qs) * 60);
        height: calc(var(--qs) * 31);
        color: var(--color-basic-quaternary-s-u);
    }
}

.widgetIcon {
    width: calc(var(--qs) * 8);
    height: calc(var(--qs) * 8);
    margin-bottom: var(--qs-5x);
}

.workspaceText {
    max-width: calc(var(--qs) * 80);
    margin-bottom: var(--qs-5x);
    text-align: center;
}
