@use '@humans-sdk/enterprise/theme/styles/breakpoints.module.scss';
@use '~include-media/dist/include-media';

$z-index999: 999;
$z-index3: 3;

.root {
    position: absolute;
    z-index: $z-index3;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-basic-quaternary-s-u);
}

.contentWrapper {
    position: relative;
    z-index: $z-index3;
    display: flex;
    height: calc(100vh - 88px);
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: var(--qs-7x) var(--qs-4x);
    margin: var(--qs-11x);
    background-color: var(--color-brand-primary-s-i);
    border-radius: var(--qs-6x);

    @include include-media.media('<tablet') {
        z-index: $z-index999;
        height: 100vh;
        padding: var(--qs-4x);
        margin: unset;
        border-radius: unset;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: var(--qs-5x);
}

.closeIcon {
    position: absolute;
    top: var(--qs-7x);
    right: var(--qs-6x);
    cursor: pointer;

    @include include-media.media('<tablet') {
        top: var(--qs-3x);
        right: var(--qs-4x);
    }
}

.blockCards {
    display: flex;
    overflow: auto;
    width: 100%;
    max-height: calc(100vh - 160px);
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: var(--qs-2x);
    gap: var(--qs-2x);

    @include include-media.media('>=tablet') {
        max-width: 520px;
        max-height: calc(100vh - 248px);
        margin: auto;
    }
}

.card {
    position: relative;
    display: flex;
    width: 140px;
    height: 92px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--qs-6x) var(--qs-3x);
    background-color: var(--color-basic-quaternary-s-u);
    border-radius: var(--qs-2x);
    row-gap: var(--qs-2x);

    @include include-media.media('>=tablet') {
        width: 168px;
        height: 120px;
    }
}

.disabled {
    opacity: 0.7;
    pointer-events: none;
}

.icon {
    width: 24px;
    height: 24px;
    font-size: var(--h3-font-size);
}

.counterBlock {
    position: absolute;
    top: var(--qs-3x);
    right: var(--qs-3x);
    display: flex;
    width: var(--qs-5x);
    height: var(--qs-5x);
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-basic-tertiary-s);
    border-radius: 50%;
}

.counterBlockActive {
    border: none;
    background-color: var(--color-basic-primary-s);
    color: var(--color-brand-primary-s-i);
}

.button {
    width: 100%;
    max-width: 530px;

    @include include-media.media('<tablet') {
        position: fixed;
        bottom: var(--qs-4x);
        width: calc(100% - var(--qs-8x));
    }
}
