:root {
    --widget-blink-z-index: 1;
}

.widget {
    display: grid;
    height: 100%;
    grid-template: 1fr / 1fr;
}

.border {
    position: absolute;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: var(--border-radius);
    pointer-events: none;
}

.blink {
    z-index: var(--widget-blink-z-index);
    animation-duration: 300ms;
    animation-iteration-count: 2;
    animation-name: blink;
    animation-timing-function: ease;
}

@keyframes blink {
    from {
        border-color: var(--color-functional-interactive-s);
    }

    to {
        border-color: var(--color-basic-tertiary-s);
    }
}
