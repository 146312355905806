.handle {
    &:hover + * {
        box-shadow: var(--shadow-2);
    }
}

.root {
    position: relative;
    overflow: hidden;
    height: 48px;
    border-radius: var(--border-radius);
    cursor: default;
    transform: translate3d(0, 0, 0);

    &.onDrag {
        box-shadow: none;
        opacity: 0.5;

        .handle {
            visibility: hidden;

            * {
                visibility: hidden;
            }
        }

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px dashed;
            border-radius: var(--border-radius);
            content: '';
        }
    }
}
