@use '@humans-sdk/enterprise/theme/styles/breakpoints.module.scss';
@use '~include-media/dist/include-media';

:root {
    --lg-header-height: 120px;
    --md-header-height: 103px;
}

$max-container-width: 1440px;

.wrapper {
    overflow: auto;
    height: 100vh;
    background: var(--color-bg-secondary-s);
}

.container {
    max-width: $max-container-width;
    padding: 0 var(--qs-8x) 0 calc(var(--qs) * 14);
    margin: 0 auto;
}

.header {
    height: var(--lg-header-height);
    box-sizing: border-box;
    padding: var(--qs-8x) 0;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.languageBtn {
    width: 115px;
    background-color: var(--color-basic-primary-s-i) !important;
}

.mainBlock {
    display: flex;
    min-height: calc(100% - 2 * var(--lg-header-height));
    align-items: center;
    gap: 114px;
}

.authDirectionsBlock {
    display: flex;
    width: 100%;
    max-width: 664px;
    flex-direction: column;
    margin-bottom: var(--qs-2x);
    gap: var(--qs-2x);
}

.upayDirectionSection {
    border-radius: 24px 24px 8px 8px;
}

.marketplaceDirectionSection {
    border-radius: 8px;
}

.enterpriseDirectionSection {
    border-radius: 8px 8px 24px 24px;
}

@include include-media.media('<tablet') {
    .container {
        padding: 0 calc(var(--qs) * 12);
    }

    .header {
        height: var(--md-header-height);
    }

    .mainBlock {
        min-height: calc(100% - 2 * var(--md-header-height));
        justify-content: center;
        gap: 50px;
    }

    .authDirectionsBlock {
        width: 100%;
        min-width: 360px;
        max-width: 500px;
    }

    .mainBlockImg {
        width: 100%;
        max-width: 352px;
    }

    .humansIconsImg {
        width: 100%;
    }

    .humansForBusinessIcon {
        width: 175px;
    }
}

@include include-media.media('<tablet') {
    .container {
        padding: 0 var(--qs-4x);
    }

    .authDirectionsBlock {
        width: 100%;
        min-width: unset;
        max-width: 592px;
    }

    .mainBlockImg {
        display: none;
    }
}

@include include-media.media('<phone') {
    .header {
        height: unset;
        padding: var(--qs-6x) 0;
    }

    .container {
        padding: 0 var(--qs-2x);
    }

    .languageBtn {
        width: 65px;
    }

    .mainBlock {
        align-items: start;
    }
}
