@use '@humans-sdk/enterprise/theme/styles/breakpoints.module.scss';
@use '~include-media/dist/include-media';

:root {
    --resize-button-z-index: 1;
}

.root {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.widgetsHeader {
    display: flex;
    align-items: center;
    margin: 0 var(--qs-4x);
    gap: var(--qs-2x);
}

.widgetTab {
    display: flex;
    overflow: hidden;
    padding: var(--qs-3x);
    border-bottom: 2px solid transparent;
    color: var(--color-basic-tertiary-s);
    cursor: pointer;
}

.widgetTabActive {
    border-bottom: 2px solid var(--color-basic-primary-s);
    color: var(--color-basic-primary-s);
}

.widgetTabText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.widgets {
    display: flex;
    overflow: auto;
    width: 100%;
    height: calc(100% - 48px);
    flex-direction: column;
    gap: var(--qs-4x);
}
