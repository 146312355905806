@use '@humans-sdk/enterprise/theme/styles/breakpoints.module.scss';
@use '~include-media/dist/include-media';

.rootContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--qs-10x) var(--qs-8x);
    background-color: var(--color-bg-primary-s);
}

.header {
    margin-bottom: var(--qs-6x);
    text-align: center;
}

.title {
    margin-bottom: var(--qs-2x);
}

.icon {
    position: absolute;
    top: var(--qs-4x);
    right: var(--qs-4x);
}

.btnSection {
    display: flex;
    width: 100%;
    max-width: 360px;
    justify-content: space-between;
    gap: var(--qs-2x);
}

.button {
    width: 100%;
}

@include include-media.media('<tablet') {
    .rootContainer {
        padding: var(--qs-10x) var(--qs-6x);
    }

    .icon {
        top: var(--qs-2x);
        right: var(--qs-2x);
        width: 78px;
    }

    .btnSection {
        display: flex;
        width: 100%;
        max-width: 310px;
        justify-content: space-between;
        gap: var(--qs-2x);
    }
}

@include include-media.media('<tablet') {
    .rootContainer {
        padding: var(--qs-8x) var(--qs-6x);
    }
}
