@use '@humans-sdk/enterprise/theme/styles/breakpoints.module.scss';
@use '~include-media/dist/include-media';

.appWrapper {
    overflow: auto;
    width: 100%;
    min-width: 320px;
    height: 100%;

    @include include-media.media('>=desktop') {
        overflow: hidden;
        height: 100vh;
        max-height: 100vh;
    }
}
